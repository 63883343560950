<template>
  <div class="page1">
    <!--面包屑-->
    <div class="breadcrumb" style="margin-bottom: 10px">
      <el-form
        label-width="70"
        :inline="true"
        style="overflow: hidden"
        class="bgFFF paddingL20"
      >
        <el-form-item label="用户总量:" style="width: 140px">{{
          totalData.memberSum
        }}</el-form-item>
        <el-form-item label="身份认证总量:" style="width: 140px">{{
          totalData.authMemberSum
        }}</el-form-item>
        <el-form-item label="绑定车辆会员数量:">{{
          totalData.bindMemberSum
        }}</el-form-item>
      </el-form>
    </div>
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div
        class="searchWrapper"
        @keydown.enter.prevent="
          pageNum = 1;
          searchjudge();
        "
      >
        <el-form
          :inline="true"
          label-position="right"
          ref="searchWrapper"
          :rules="searchRule"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.phone_number')" prop="mobile">
                <el-input
                  :maxlength="11"
                  v-model.trim="formInline.mobile"
                  placeholder="请输入手机号"
                />
              </el-form-item>
              <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber" ref="plateNumber">
                <el-autocomplete
                  ref="plateNumber"
                  size="11"
                  valueKey="plateNumber"
                  class="inline-input"
                  v-model="formInline.plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入车牌号"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.time_slot')">
                <timeRangePick
                  @timeChange="timeChange"
                  ref="timeRangePicker"
                  :defalutDate="defalutDate"
                />
              </el-form-item>
            </div>
            <div class="col_right">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['button.query']"
                icon="el-icon-search"
                type="primary"
                :loading="loading"
                @click="
                  pageNum = 1;
                  searchjudge();
                "
                >查询
              </AuthorityComponent>
            </div>
          </div>
          <!-- <el-button type="primary" @click="pageNum=1;searchjudge()" :loading="loading">{{ $t('button.search') }}</el-button> -->
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper paddingB10 bgFFF">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <!--<el-table-column type="index" :label="$t('list.index')" width="70" align='center' ></el-table-column>-->
          <el-table-column
            :prop="item.prop"
            :align="center"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="身份认证" width="" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.authState == 0" style="color: red"
                >未认证</span
              >
              <span v-if="scope.row.authState == -1" style="color: red"
                >未认证</span
              >
              <span v-if="scope.row.authState == 1">已认证</span>
            </template>
          </el-table-column>
          <AuthorityComponent
            ComponentName="el-table-column"
            :permission="['button.view']"
            label="操作"
            align="center"
            width="100"
          >
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="showDetail(scope.row)"
                >查看</el-button
              >
            </template>
          </AuthorityComponent>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import timeRangePick from "@/components/timePicker";
import { dateFormat, setIndex } from "../../common/js/public.js";
export default {
  name: "memberInfo",
  data() {
    // const startCreateTime = this.$moment().subtract(30, 'days').format('YYYY-MM-DD') + ' 00:00:00'
    const startCreateTime =
      this.$moment().subtract(6000, "days").format("YYYY-MM-DD") + " 00:00:00";
    const endCreateTime = this.$moment().format("YYYY-MM-DD") + " 23:59:59";
    const startTime =
      this.$moment().subtract(30, "d").format("YYYY-MM-DD") + " 00:00:00";
    const endTime = this.$moment().format("YYYY-MM-DD") + " 23:59:59";
    let checkPhone = (rule, value, callback) => {
      if (!/^\d{11}$/.test(value) && value) {
        callback(new Error("手机号格式不正确"));
      } else {
        callback();
      }
    };
    let checkParkNum = (rule, value, callback) => {
      let len = value.length;
      if (!value) {
        callback();
      } else if (len < 3) {
        callback(new Error("至少输入三个字符"));
      } else if (len > 3 && len <= 11) {
        callback();
      } else if (len > 11) {
        callback(new Error("最多输十一个字符"));
      }
    };
    return {
      defalutDate: [startTime, endTime],
      parkDetail: [],
      rowData: [],
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      date1: startCreateTime,
      date2: endCreateTime,
      searchRule: {
        mobile: [
          {
            validate: checkPhone,
            trigger: "blur",
          },
        ],
        plateNumber: [
          {
            validate: checkParkNum,
            trigger: "blur",
          },
        ],
      },
      tableCols: [
        {
          prop: "index",
          label: "序号",
          width: "70",
        },
        {
          prop: "memberId",
          label: "用户ID",
        },
        {
          prop: "mobile",
          label: this.$t("list.phone_number"),
        },
        {
          prop: "nickName",
          label: "用户昵称",
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
        },
        {
          prop: "sexValue",
          label: "性别",
        },
        {
          prop: "balance",
          label: "余额(元)",
          formatter: (row, column, cellValue, index) => {
            return cellValue / 100;
          },
        },
        {
          prop: "registerTime",
          label: "注册日期",
        },
        {
          prop: "carInfo",
          label: "绑定车牌",
          width: "165",
        },
      ],
      tableData: [],
      totalData: {},
      formInline: {
        startTime: "",
        endTime: "",
        // gender: '',
        // city: '',
        mobile: "",
        // authState: '',
        // startDate: dateFormat(startCreateTime, 'yyyy-MM-dd HH:mm:ss'),
        // endDate: dateFormat(endCreateTime, 'yyyy-MM-dd HH:mm:ss'),
        plateNumber: "",
        carId: "",
      },
      // timeArray: [startCreateTime, endCreateTime],
      areaList: [],
    };
  },
  components: { timeRangePick },
  methods: {
    // 日期插件返回的日期
    timeChange(timeArr) {
      console.log(timeArr, "timeArr");
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    // 加载区域列表
    initAreaList() {
      const opt = {
        url: "/acb/2.0/systems/loginUser/initAreaList",
        method: "get",
        data: {},
        success: (res) => {
          this.areaList = res.value.areaList;
        },
      };
      this.$request(opt);
    },
    showDetail(cmd, data) {
      let obj = this.$route.meta.authority;
      obj.subPage.view.tabs.userMemberLevel = {
        button: { export: 1, query: 1 },
        subPage: {},
        tabs: {},
      };
      obj.subPage.view.tabs.userCreditLevel = {
        button: { export: 1, query: 1 },
        subPage: {},
        tabs: {},
      };
      sessionStorage.subPageAuthorityMemeber = JSON.stringify(obj);
      this.$router.push({
        path: "/memberinformationdetail",
        query: { data: JSON.stringify(cmd), memberId: cmd.memberId },
      });
    },
    setStartTime(value) {
      this.formInline.startDate = dateFormat(value);
    },
    setEndTime(value) {
      this.formInline.endDate = dateFormat(value);
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    querySearchAsync(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },
    handleSelect(item) {
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
      this.searchData();
    },
    showLog() {
      return true;
      // if (this.date1 && this.date2) {
      //   let startTime = dateFormat(this.date1, 'yyyy-MM-dd HH:mm:ss');
      //   let endTime = dateFormat(this.date2, 'yyyy-MM-dd HH:mm:ss');
      //   this.formInline.startDate = startTime || this.date1;
      //   this.formInline.endDate = endTime || this.date2;
      //   var time = new Date(this.formInline.endDate) - new Date(this.formInline.startDate);
      //   time = time / (24 * 60 * 60 * 1000);
      //   if (time > 31) {
      //     this.$alert("请缩小搜索范围，搜索时间范围不超过31天");
      //     return false;
      //   }
      //   if (this.formInline.startDate > this.formInline.endDate) {
      //     this.$alert('开始时间不能大于结束时间')
      //     return false;
      //   } else {
      //     return true
      //   }
      // } else {
      //   this.formInline.startDate = '';
      //   this.formInline.endDate = '';
      //   this.$alert('时间段不能为空', this.$t('pop_up.Tips'), {
      //     confirmButtonText: this.$t('pop_up.Determine')
      //   });
      //   return false
      // }
    },
    searchjudge() {
      let flag = this.showLog();
      this.$refs["searchWrapper"].validate((v) => {
        if (v) {
          if (flag) {
            this.searchData();
          }
        }
      });
    },
    /* 车牌号 */
    getplateNumber() {
      let opt = {
        url: "/acb/2.0/plateNumber",
        method: "get",
        data: {
          part: "",
          size: 100,
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.parkList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 搜索
    searchData() {
      // let flag = this.showLog();
      // this.$refs['searchWrapper'].validate((v) => {
      //   if (v) {
      this.loading = true;
      // let startDate = ''
      // let endDate = ''
      // if (this.timeArray) {
      //   startDate = this.timeArray[0]
      //   endDate = this.timeArray[1]
      // }
      console.log(this.formInline, "this.formInline");
      this.$axios
        .post("/acb/2.0/memberInfo/query", {
          data: {
            page: this.pageNum,
            pageSize: this.pageSize,
            // sex: this.formInline.sex,
            // city: this.formInline.city,
            // authState: this.formInline.authState,
            mobile: this.formInline.mobile,
            // nickName: this.formInline.nickName,
            startDate: this.formInline.startTime,
            endDate: this.formInline.endTime,
            carId: this.formInline.carId,
          },
        })
        .then((res) => {
          this.loading = false;
          if (!res.value) {
            this.total = 0;
            this.tableData = [];
            return;
          }
          this.totalData = res.value.total;
          if (res.value.list.list) {
            this.total = parseInt(res.value.list.total);
            this.tableData = setIndex(this.pageNum, res.value.list.list);
          } else {
            this.total = 0;
            this.tableData = [];
          }
        });
      //   }
      // })
    },
  },
  created() {
    this.initAreaList();
    // this.searchData()
  },
  activated() {
    this.searchData();
  },
  watch: {
    "formInline.plateNumber": {
      handler(newName, oldName) {
        if (!newName) {
          this.formInline.carId = "";
        }
      },
      deep: true,
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}
</style>
